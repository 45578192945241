<template>
  <v-container my-10>
    <v-layout align-center justify-center>
      <v-progress-circular
        :size="55"
        :width="4"
        indeterminate
        class="mt-12"
        v-if="loading"
        color="primary"
      />

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        v-if="!loading"
        @submit.prevent="openCreateInitialUserDialog"
      >
        <v-card color="primary" dark max-width="650px" v-if="!loading" tile>
          <v-card-title>
            <span class="headline" v-if="isEditView"
              >{{ editedItem.name }}
            </span>
            <span class="headline" v-else>New location</span>
          </v-card-title>
          <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
            <v-tab
              v-for="tab of tabs"
              :key="tab.id"
              :href="'#' + tab.href"
              :disabled="tab.disabled"
            >
              <v-icon class="mr-2">{{ tab.icon }}</v-icon>
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items :value="tab">
            <v-tab-item value="info">
              <v-card min-width="650px" v-if="!loading">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name*"
                          :rules="dealerNameRule"
                          required
                          autocomplete="new-password"
                        />
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.address"
                          label="Address"
                          autocomplete="new-password"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.city"
                          label="City"
                          autocomplete="new-password"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.country"
                          label="Country"
                          autocomplete="new-password"
                        />
                      </v-col> -->
                      <!-- <v-col
                        v-if="!isEditView"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                      >
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          autocomplete="new-password"
                        />
                      </v-col> -->
                      <!-- <v-col cols="12" sm="12" md="12" lg="12"> -->
                      <!-- <v-text-field
                          v-model="editedItem.website"
                          label="Website"
                          autocomplete="new-password"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Phone"
                          autocomplete="new-password"
                        />
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="close">Cancel</v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="openCreateInitialUserDialog"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item value="users">
              <v-card max-width="650px" width="650px" v-if="!loading">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" v-if="editedItem.users.length !== 0">
                      <v-card-subtitle
                        class="mb-0 pb-0 grey--text text--darken-2"
                      >
                        Added Users:
                      </v-card-subtitle>

                      <div
                        v-if="loadingUsers"
                        class="d-flex justify-center align-center"
                      >
                        <v-progress-circular
                          :size="55"
                          :width="4"
                          indeterminate
                          class="mt-12"
                          color="primary"
                        />
                      </div>
                      <div v-if="!loadingUsers">
                        <v-list
                          v-for="(user, index) in editedItem.users"
                          :key="user.uuid"
                        >
                          <user-email-component
                            :user="user"
                            :contact-email="editedItem.email"
                            :readonly="false"
                            :is-owner="isCurrentUserOwenerOfPartnership"
                            @delete-user="deleteUserDialog(index)"
                            @update-role="handleUpdateRole"
                          >
                            <template
                              slot="role"
                              v-if="
                                typeof user.labels !== 'undefined' &&
                                  user.labels.length !== 0
                              "
                            >
                              <v-chip
                                outlined
                                class="mt-2 mr-1"
                                v-for="item in getRolePrettified(user.labels)"
                                :key="item.id"
                              >
                                {{ item.name }}
                              </v-chip>
                            </template>
                          </user-email-component>
                        </v-list>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card-subtitle
                        class="mb-0 pb-0 grey--text text--darken-2"
                      >
                        Invite Users:
                      </v-card-subtitle>
                      <user-email-invite
                        class="mx-6"
                        :initial_data="editedItem.pending_invites"
                        :notAvailable="editedItem.users"
                        @invite-user="inviteUser"
                        @reject-user="rejectUser"
                        :contact-email="editedItem.email"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-form>
    </v-layout>
    <warning-dialog
      :dialog="cancelDialog.active"
      :text="cancelDialog.text"
      :title="cancelDialog.title"
      @agree="handleCancelDialogOutput"
      :confirm="'Yes'"
      :reject="'No'"
      v-show="false"
    />
    <warning-dialog
      :dialog="createInitialUserDialog.active"
      :text="createInitialUserDialog.text"
      :title="createInitialUserDialog.title"
      @agree="save"
      :confirm="'Yes'"
      :reject="'No'"
      v-show="false"
    />
    <warning-dialog
      :dialog="existingRoleDialog.active"
      :text="existingRoleDialog.text"
      :title="existingRoleDialog.title"
      @agree="handleRoleChange"
      :confirm="'Proceed'"
      :reject="'Cancel'"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import UserEmailInvite from "@/components/partners/UserEmailInvite";
import snackbar from "@/utils/snackbar.js";
import validation from "@/utils/validation.js";
import UserEmailComponent from "@/components/partners/UserEmailComponent";
import { mapGetters } from "vuex";
import { COMPUTE_CRM_GROUPS_WITH_WRITE_PERMISSION } from "@/store/actions/permissions";
import constants from "@/utils/constants";
import { USER_CRM_REQUEST } from "@/store/actions/user";

export default {
  components: {
    UserEmailInvite,
    UserEmailComponent,
    WarningDialog,
  },
  data: () => ({
    tabs: [],
    valid: true,
    dealerNameRule: [(v) => !!v || "Location name is required"],
    emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    loading: false,
    isEditView: false,
    loadingUsers: false,
    editedItem: {
      name: "",
      brand_uuids: [],
      crm_group_uuid: "",
      brands: [],
      dealer: [],
      vendor: null,
      domains: [],
      address: "",
      city: "",
      country: "",
      email: "",
      website: "",
      phone: "",
      users: [],
      user_emails: [],
      create_initial_user: true,
    },
    indexToDelete: null,
    cancelDialog: {
      active: false,
      title: "Remove user",
      text: "Are you sure you want to remove user from the dealership",
    },
    createInitialUserDialog: {
      active: false,
      title: "Add user",
      text: "",
    },
    existingRoleDialog: {
      active: false,
      roles: [],
      user: {},
      title: "Warning",
      text:
        "The new user will become the owner of this account. Your account will loose access to features available only to owners. THIS CANNOT BE UNDONE",
    },
    availableRoles: [
      { name: "Communicator", id: "2" },
      { name: "Admin", id: "1" },
    ],
  }),

  computed: {
    ...mapGetters([
      "getMe",
      "getCrmGroupsWithWritePermission",
      "getCanUserWritePartners",
    ]),
    tab: {
      set(tab) {
        this.$router.replace({ params: { ...this.$route.params, tab } });
      },
      get() {
        return this.$route.params.tab;
      },
    },
    isCurrentUserOwenerOfPartnership() {
      if (typeof this.getMe.user === "undefined") return false;

      const checkForPermissionsOfCurrentUser = this.editedItem.users.find(
        (item) => {
          return this.getMe.user.username === item.username;
        }
      );

      if (
        typeof checkForPermissionsOfCurrentUser === "undefined" ||
        typeof checkForPermissionsOfCurrentUser.labels === "undefined"
      ) {
        console.log(
          "Invalid user or labels not found",
          this.getMe.user.username
        );
        return false;
      }

      // this.getCanUserWritePartners the creator can also reassign owner of the partner
      return (
        checkForPermissionsOfCurrentUser.labels.includes("ADMIN") ||
        this.getCanUserWritePartners
      );
    },
  },

  created() {
    this.setInitialData();
  },

  methods: {
    setInitialData() {
      this.setTabs();
      this.$store.dispatch(COMPUTE_CRM_GROUPS_WITH_WRITE_PERMISSION);

      if (this.$route.name === "PartnersEdit") {
        this.loading = true;
        this.isEditView = true;
        api.getDealer(this.$route.params.id).then((resp) => {
          this.setTabs();
          this.editedItem = resp.data;
          this.editedItem.crm_group_uuid = this.editedItem.crm_group.uuid;
          this.editedItem.brand_uuids = this.editedItem.brands.map(
            (brand) => brand.uuid
          );
          this.loading = false;
        });
      }
    },
    getTabs() {
      this.tabs = [
        {
          name: "information",
          id: "information",
          icon: "mdi-information",
          href: "info",
          disabled: false,
        },
        {
          name: "Users",
          id: "users",
          icon: "mdi-cog",
          href: "users",
          disabled: false,
        },
      ];
    },
    setTabs() {
      this.getTabs();
      const findTabIndex = (id, tabs) => {
        return tabs.findIndex((element) => {
          return element.id === id;
        });
      };

      if (!this.isEditView) {
        let index = findTabIndex("users", this.tabs);
        this.tabs[index].disabled = true;
      }
    },

    getRolePrettified(roles) {
      const partnerRoles = constants.partnerRoles;

      return partnerRoles.filter((item) => {
        return roles.includes(item.server);
      });
    },

    inviteUser(user, done) {
      api
        .inviteDealerUser(this.editedItem.uuid, {
          email: user,
        })
        .then((resp) => {
          snackbar.success("User successfully invited");
          api.getDealer(this.editedItem.uuid).then((response) => {
            let findUser = response.data.users.find(
              (item) => item.username === resp.data.email
            );
            if (findUser) {
              this.editedItem.users.push(findUser);
              done(resp, true);
            } else {
              done(resp, false);
            }
          });
        })
        .catch((error) => {
          if (
            typeof error.response.data.error_type !== "undefined" &&
            error.response.data.error_type === "MEMBER_OF_OTHER_SUPERGROUPS"
          ) {
            snackbar.error(error.response.data.error);
          }
          this.loading = false;
        });
    },
    rejectUser(user, done) {
      api.rejectInviteDealerUser(user.token).then((reps) => {
        snackbar.success("User was rejected");
        done(user);
      });
    },
    deleteUserDialog(index) {
      this.indexToDelete = index;
      this.cancelDialog.active = true;
    },
    handleCancelDialogOutput(agreed) {
      this.loading = false;
      if (agreed) {
        api
          .deleteUserFromDealers(this.editedItem.uuid, {
            uuid: this.editedItem.users[this.indexToDelete].uuid,
          })
          .then((resp) => {
            this.editedItem.users.splice(this.indexToDelete, 1);
            snackbar.success("User successfully removed");
            this.indexToDelete = null;
          });
      } else {
        this.indexToDelete = null;
      }
      this.cancelDialog.active = false;
    },
    close(special = false, uuid = null) {
      // if (special) {
      //   this.$router.push({
      //     name: "Partners",
      //     query: { special: true, id: uuid, tab: "users" },
      //   });
      //   return;
      // }

      if (this.getCrmGroupsWithWritePermission.length !== 0) {
        if (this.getCrmGroupsWithWritePermission[0].group_type === "CHARTER") {
          this.$router.push({
            name: "Partners",
          });
        }
      }
      //  else {
      //   this.$router.push({
      //     name: "PartnersDetails",
      //     params: { id: this.editedItem.uuid },
      //   });
      // }
    },

    isUserAdded(user) {
      return (
        this.editedItem.users.filter((item) => {
          return item.username === user;
        }).length === 0
      );
    },

    openCreateInitialUserDialog() {
      if (this.$refs.form.validate()) {
        // if (
        //   this.editedItem.email !== "" &&
        //   this.isUserAdded(this.editedItem.email)
        // ) {
        //   this.createInitialUserDialog.text = `Do you want to create user ${this.editedItem.email}?`;
        //   this.createInitialUserDialog.active = true;
        // } else {
        this.save();
        // }
      } else {
        snackbar.error("Please fill in all required fields");
      }
    },

    handleUpdateRole(event) {
      if (event.roles.every((item) => item === "COMMUNICATION_MANAGER")) {
        this.existingRoleDialog = {
          ...this.existingRoleDialog,
          roles: event.roles,
          user: event.user,
          active: false,
        };
        this.handleRoleChange(true);
        return;
      }

      this.existingRoleDialog = {
        ...this.existingRoleDialog,
        roles: event.roles,
        user: event.user,
        active: true,
      };
    },

    handleRoleChange(event) {
      if (!event) {
        this.existingRoleDialog = {
          ...this.existingRoleDialog,
          active: false,
          roles: [],
          user: {},
        };
        return;
      }

      api
        .setUserLabels(
          this.editedItem.uuid,
          this.existingRoleDialog.user.uuid,
          this.existingRoleDialog.roles
        )
        .then((value) => {
          this.loadingUsers = true;

          this.$store.dispatch(USER_CRM_REQUEST);

          api.getDealer(this.editedItem.uuid).then((resp) => {
            this.editedItem.users = resp.data.users;
            this.loadingUsers = false;

            this.existingRoleDialog = {
              ...this.existingRoleDialog,
              active: false,
              roles: [],
              user: {},
            };
          });
        });
    },

    save(event) {
      // if (typeof event !== "undefined" && event) {
      //   this.editedItem.create_initial_user = true;
      // } else {
      //   this.editedItem.create_initial_user = false;
      // }
      if (this.$route.name !== "PartnersEdit") {
        this.editedItem.crm_group_uuid = this.getMe.crm_groups.find((group) => {
          return group.is_supergroup && group.group_type !== "PARTNER";
        }).uuid;
      }
      this.editedItem.create_initial_user = true;
      if (this.isEditView) {
        api.updateDealer(this.editedItem.uuid, this.editedItem).then((resp) => {
          snackbar.success("Successfully saved");
          this.close();
        });
      } else {
        this.loading = true;
        api.createDealer(this.editedItem).then((resp) => {
          snackbar.success("Successfully saved");
          // this.close(true, resp.data.uuid);
          this.$router.replace({
            name: "PartnersEdit",
            params: { id: resp.data.uuid, tab: "users" },
          });

          this.tab = "users";
          this.editedItem = resp.data;
          this.isEditView = true;
          this.setTabs();
          this.loading = false;
        });
      }
    },
  },
};
</script>
