var validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

var getUserEmails = (data, editedItem) => {
  if (
    typeof data.users !== "undefined" &&
    typeof editedItem.user_emails !== "undefined"
  ) {
    var emails = data.users.map(user => {
      return user.username;
    });
    return editedItem.user_emails.filter(x => !emails.includes(x));
  } else {
    return null;
  }
};

var handleWarningDialog = (resp, editedItem) => {
  var emails = getUserEmails(resp.data, editedItem);
  if (emails && emails.length !== 0) {
    return getDialogText(emails);
  } else if (emails == null) {
    var user_emails = resp.data.users.map(user => {
      return user.username;
    });
    return getDialogText(user_emails);
  } else {
    return "";
  }
};

var getDialogText = emails => {
  return emails.join("\r\n");
};

export default {
  validateEmail,
  handleWarningDialog
};
