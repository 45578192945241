<template>
  <div>
    <v-autocomplete
      autocomplete="new-password"
      v-model="userEmailToAdd"
      item-text="email"
      item-value="email"
      :search-input.sync="userSearch"
      color="primary"
      @keypress.enter.prevent="addUser(userSearch)"
      @focus="showMargin = true"
      @blur="showMargin = false"
      label="Enter e-mail address"
      required
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Press <kbd>enter</kbd> to invite user.
              <span v-if="emailCorrectText">Enter correct email address.</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-list v-for="(user, index) in userEmails" v-bind:key="user.email">
      <div v-if="index == 0">
        <div :class="{ 'show-margin': showMargin }">
          <user-email-component
            :readonly="false"
            :user="user"
            @delete-user="deleteUser(user)"
            :invite="true"
            :contact-email="contactEmail"
          ></user-email-component>
        </div>
      </div>
      <div v-else>
        <user-email-component
          :readonly="false"
          :user="user"
          @delete-user="deleteUser(user)"
          :invite="true"
          :contact-email="contactEmail"
        ></user-email-component>
      </div>
    </v-list>
    <warning-dialog
      :dialog="inviteDialog.active"
      :text="inviteDialog.text"
      :title="inviteDialog.title"
      @agree="handleInviteDialogOutput"
      :confirm="'Yes'"
      :reject="'No'"
      v-show="false"
    ></warning-dialog>
    <warning-dialog
      :dialog="cancelDialog.active"
      :text="cancelDialog.text"
      :title="cancelDialog.title"
      @agree="handleCancelDialogOutput"
      :confirm="'Yes'"
      :reject="'No'"
      v-show="false"
    ></warning-dialog>
  </div>
</template>

<script>
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import UserEmailComponent from "@/components/partners/UserEmailComponent";
import validation from "@/utils/validation";

export default {
  name: "UserEmailPicker",
  components: { UserEmailComponent, WarningDialog },
  props: ["initial_data", "notAvailable", "contactEmail"],
  data: () => ({
    inviteDialog: {
      active: false,
      title: "Invite user",
      text: "",
    },
    cancelDialog: {
      active: false,
      title: "Cancel invite",
      text: "Are you sure you want to cancel the invite",
    },
    showMargin: false,
    emailCorrectText: false,
    userSearch: "",
    isEditView: false,
    userEmailToAdd: null,
    userOptions: [],
    userEmails: [],
    user: {},
  }),
  created() {
    if (
      typeof this.initial_data !== "undefined" &&
      this.initial_data.length !== 0
    ) {
      this.userEmails = this.initial_data;
    }
  },

  methods: {
    handleInviteDialogOutput(agreed) {
      if (agreed) {
        this.emailCorrectText = false;
        const self = this;
        this.$emit("invite-user", this.user, function(done, status) {
          if (done) {
            self.$nextTick(() => {
              self.userSearch = "";
              self.userEmailToAdd = null;
              if (!status) {
                self.userEmails.push(done.data);
              }
            });
          }
        });
      } else {
        this.userSearch = "";
        this.userEmailToAdd = null;
      }
      this.inviteDialog.active = false;
    },
    handleCancelDialogOutput(agreed) {
      this.loading = false;
      if (agreed) {
        const self = this;
        this.$emit("reject-user", this.user, function(done) {
          if (done) {
            self.$nextTick(() => {
              self.userEmails = self.userEmails.filter(
                (ue) => ue.email !== done.email
              );
            });
          }
        });
      }
      this.cancelDialog.active = false;
    },
    addUser(user) {
      this.user = user;
      if (!validation.validateEmail(this.user)) {
        this.emailCorrectText = true;
        return;
      }

      if (this.notAvailable.find((el) => el.username === this.user)) {
        return;
      }

      if (this.userEmails.find((el) => el === this.user)) {
        return;
      }

      this.inviteDialog.text =
        "Are you sure you want to add user " +
        this.user +
        "? The user will be informed by email.";
      this.inviteDialog.active = true;
    },
    deleteUser(user) {
      this.user = user;
      this.cancelDialog.active = true;
    },
  },
};
</script>
<style>
.flex-ml-auto {
  margin-left: auto;
}
.show-margin {
  margin-top: 50px;
}
</style>
