<template>
  <v-card flat class="ma-0 pa-0">
    <v-card-subtitle class="my-0 py-0">
      <v-container fluid class="px-0 py-0">
        <v-row no-gutters justify="start" class="flex-nowrap">
          <v-col cols="auto">
            <v-btn
              text
              class="text-lowercase grey--text text--darken-2 mt-2 pl-2"
              @click="showDetails = !showDetails"
              small
            >
              <v-icon class="mr-2" v-if="!isInvite">mdi-account</v-icon>
              <v-icon class="mr-2" v-else>mdi-account-clock</v-icon>
              <span v-if="!isInvite" class="float-right">{{
                user.username
              }}</span>
              <span v-else class="float-right">{{ user.email }}</span>
            </v-btn>
          </v-col>
          <v-col>
            <slot name="role"></slot>
          </v-col>
          <v-col cols="1" v-if="!isReadOnly">
            <v-btn
              v-if="!isRemovable"
              class="float-right mt-2"
              icon
              @click="deleteUser()"
            >
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" v-if="typeof user.token === 'undefined'">
            <v-menu v-model="menu" :close-on-content-click="false" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="float-right mt-2" icon v-bind="attrs" v-on="on">
                  <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card width="320px">
                <v-list>
                  <v-list-item>
                    <v-list-item-content class="pb-0">
                      <!-- <v-autocomplete
                        v-model="role"
                        label="Select user's role"
                        item-text="name"
                        :items="roles"
                        multiple
                        chips
                        return-object
                      ></v-autocomplete> -->
                      <v-list-item-title class="pb-0">
                        Select user's role:
                      </v-list-item-title>

                      <v-chip-group
                        v-model="role"
                        multiple
                        active-class="primary--text"
                      >
                        <v-chip
                          v-for="(role, index) in roles"
                          :key="index"
                          outlined
                          :value="role"
                        >
                          {{ role.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text @click="handleCloseRole">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="handleSaveRole">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import constants from "@/utils/constants";
export default {
  name: "UserEmailComponent",
  props: ["user", "readonly", "invite", "contactEmail", "isOwner"],
  computed: {
    isReadOnly() {
      return !!this.readonly;
    },
    isInvite() {
      return !!this.invite;
    },
    isRemovable() {
      if (typeof this.user.labels === "undefined") {
        return false;
      }
      return this.user.labels.includes("ADMIN");
    },
  },
  data: () => ({
    showDetails: false,
    menu: false,
    roles: [],
    role: [],
  }),
  created() {
    this.roles = constants.partnerRoles;

    if (!this.isOwner) {
      this.roles = constants.partnerRoles.filter((item) => {
        return item.server !== "ADMIN";
      });
    }
    // this.role = user.roles;
  },

  watch: {
    isOwner(newValue, oldValue) {
      if (!newValue) {
        this.roles = constants.partnerRoles.filter((item) => {
          return item.server !== "ADMIN";
        });
        this.role = [];
      }
    },
  },

  methods: {
    deleteUser() {
      this.$emit("delete-user");
    },
    handleCloseRole() {
      this.menu = false;
    },
    handleSaveRole() {
      if (this.role.length === 0) return;

      this.$emit("update-role", {
        roles: this.role.map((item) => {
          return item.server;
        }),
        user: this.user,
      });

      this.role = [];
      this.menu = false;
    },
  },
};
</script>
